<template>
  <div>
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-connection" @click="handleBatch">批次轉換</el-button>
      <div style="float: right;">
        <el-select v-model="listQuery.serviceId" clearable size="small" v-bind:placeholder="$t('AllOperator')"
          class="filter-item-mid" v-if="serviceList.length > 0">
          <el-option v-for="item in serviceList" :key="item.id" :label="item.name + ' (' + item.code + ')'"
            :value="item.id" />
        </el-select>
        <!--
                <el-date-picker v-model="dateRange" size="small" class="filter-item" type="daterange"
                  value-format="yyyy-MM-dd" range-separator="-" :start-placeholder="$t('Start')+$t('Date')"
                  :end-placeholder="$t('End')+$t('Date')">
                </el-date-picker>
                -->
        <el-date-picker v-model="dateStart" :default-vaule="listQuery.startdate" size="small" class="filter-item"
          type="date" value-format="yyyy-MM-dd">
        </el-date-picker>
        <el-date-picker v-model="dateEnd" size="small" class="filter-item" type="date" value-format="yyyy-MM-dd"
          :default-vaule="listQuery.enddate">
        </el-date-picker>
        <el-input size="small" v-model="listQuery.name" placeholder="通行碼名稱" class="filter-item" clearable
          style="width: 180px;" />
      </div>
    </el-row>
    <el-row
      style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;">
      <span>產製時間: {{ reportDate }} </span>
    </el-row>
    <!-- table -->
    <el-table v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{ padding: '0', height: '40px' }"
      :default-sort="defaultSort" @sort-change="logTableSortChange">
      <el-table-column align="center" prop="uid" label="通行碼" :show-overflow-tooltip="true" sortable="custom" />
      <el-table-column align="center" prop="serviceId" label="營運商" sortable="custom" v-if="serviceList.length > 0">
        <template slot-scope="scope">
          <span :title="scope.row.countryCode + '/' + scope.row.partyId">{{ getServiceName(scope.row.serviceId) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="type" label="類別" sortable="custom" />
      <el-table-column align="center" prop="valid" label="有效的">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.valid" type="success">{{ $t('Enabled') }}</el-tag>
          <el-tag v-else type="danger">{{ $t('NotEnabled') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="contractId" label="合約編號" />
      <el-table-column align="center" prop="visualNumber" label="可識名稱" />
      <el-table-column align="center" prop="whitelist" label="白名單策略" />
      <!--<el-table-column align="center" prop="issuer" label="發行單位" />-->
      <el-table-column align="center" prop="lastUpdated" label="更新日期" :show-overflow-tooltip="true" width="180px"
        sortable="custom">
        <template slot-scope="scope">
          <span :title="scope.row.timestamp">{{ parseDateTimeToHHMMSS(scope.row.lastUpdated) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total > 0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size"
      @pagination="getList" />

    <TokenBatchSetting :dialogVisible="dialogVisible" :serviceList="serviceList" @close="dialogDetailOnClose()" />
  </div>
</template>
  
  <script>
  import TokenConfigSetting from '@/views/ocpi/tokenBatchSetting'
  import Pagination from '@/components/Pagination'
  import { mixins } from '@/views/common/mixins.js'
  import {refreshToken,stopRefreshToken} from "@/utils/auth";
  import {apiUrl} from "@/utils/main";
  import { ColorPicker } from 'element-ui';
import TokenBatchSetting from './tokenBatchSetting.vue';
  
  export default {
    name: 'Service',
    components: {
      Pagination,
      TokenBatchSetting
    },
    mixins: [mixins],
    data() {
      return {
        dialogWidth: 0,
        total: 0,
        logs: [],  // 用來儲存原始 logs 數據
        list: [],
        listLoading: false,
        dialogVisible: false,
        listQuery: {
          type: '',
          enabled: true,
          serviceId: undefined,
          startdate: undefined,
          enddate: undefined,
          name: undefined,
          item: undefined,
          page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
          size: 15
        },
        defaultSort: { prop: 'timestamp', order: 'descending' },
        dateRange: undefined,
        dateStart: undefined,
        dateEnd:   undefined,
        serviceList: [],
        paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
        reportDate: this.formatCurrentDateTime()
      };
    },
    watch: {
      paginationSelectedPage: {
        handler: function(newValue) {
          this.listQuery.page = newValue - 1
        }
      },
      'listQuery.serviceId': function () {
            this.listQuery.page = 0
            this.resetPage(this.getList)
      },
      'listQuery.name': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      'listQuery.item': function() {
        this.listQuery.page = 0
        this.resetPage(this.getList)
      },
      /*
      dateRange: function(val) {
        if (val) {
          let sdt = this.$moment(this.dateRange[0]).tz('Asia/Taipei').format() // 轉為台灣時區
          let edt = this.$moment(this.dateRange[1]).tz('Asia/Taipei').format() // 轉為台灣時區
          sdt = sdt.substring(0,19) + 'Z'
          edt = edt.substring(0,19) + 'Z'
          console.log("change dateRange: " + sdt + " - " + edt)
          this.listQuery.startdate = sdt
          this.listQuery.enddate = edt
          this.resetPage(this.getList)
        } else {
          this.listQuery.startdate = undefined
          this.listQuery.enddate = undefined
          // 未輸入
          this.$message({
              message: "請輸入時間範圍",
                type: 'error'
          })
        }        
      },*/
      dateStart: function(val) {
        if (val) {
          this.listQuery.startdate = this.dateStart
        } else {
          this.listQuery.startdate = undefined
        }
          this.listAll= [];
          this.listQuery.page = 0
          this.resetPage(this.getList)
      },   
      dateEnd: function(val) {
        if (val) {
          this.listQuery.enddate = this.dateEnd
        } else {
          this.listQuery.enddate = undefined
        }
          this.listAll= [];
          this.listQuery.page = 0
          this.resetPage(this.getList)
      },
    },
    async created() {
      let date = new Date()
      let daysAgo = new Date()

      daysAgo = daysAgo.setDate(daysAgo.getDate()-7)
      this.listQuery.startdate = this.parseDateTime(daysAgo)
      this.dateStart = this.parseDateTime(daysAgo)
      this.dateEnd = this.parseDateTime(date)
      this.listQuery.enddate = this.parseDateTime(date)

      this.dialogWidth = this.setDialogWidth()
      this.getServiceOptions()
      
      //上面設定日期後觸發 getList 動作
      //this.resetPage(this.getList)
    },
    beforeDestroy(){
      console.log("call beforeDestroy");
      //stopRefreshToken();
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          this.dialogWidth = this.setDialogWidth()
        })()
      }
    },
    methods: {
      async getList() {
        this.listLoading = true
        this.axios.get('/api/v1/ocpi/token', { params: this.listQuery }).then(res => {
            this.list = res.data.content
            // console.log(this.list)
            this.total = res.data.totalElements
            this.listLoading = false

            this.reportDate = this.formatCurrentDateTime()
        })
      },
      getServiceOptions() {
        this.listLoading = true
        this.axios.get('/api/v1/common/service/enabled').then(res => {
            this.serviceList = res.data
        })
      },
      logTableSortChange(column) { // 自定義排序方式
        this.listLoading = true
        this.list = []
        if (column.prop.indexOf('profile.') != -1) {
          column.prop = column.prop.replace('profile.','')
        }

        //console.log("logTableSortChange: " + column.prop);
        if (column.order === 'descending') {
          if(column.prop === 'timestamp') {
           
            this.logs.sort(function(a, b){return b.timestamp - a.timestamp})
            //console.log(this.logs.length);
          } else {
            this.logs.sort(function(a, b){
              if (a[column.prop] > b[column.prop]) {
                return -1;
              }
              if (b[column.prop] > a[column.prop]) {
                return 1;
              }
              return 0;
            })
          }
          //this.listQuery.sort = column.prop + ',' + 'DESC'
        } else {
          if(column.prop === 'timestamp') {
            
            this.logs.sort(function(a, b){return a.timestamp - b.timestamp})
          } else {
            this.logs.sort(function(a, b){
              if (a[column.prop] > b[column.prop]) {
                return 1;
              }
              if (b[column.prop] > a[column.prop]) {
                return -1;
              }
              return 0;
            })
          }

          
        }
        console.log(this.logs.length);
          for(let i=0,  p = this.listQuery.page*this.listQuery.size; p < (this.listQuery.page+1)*this.listQuery.size && p < this.total; p++, i++ ) {
            this.list[i] = this.logs[p]
          }
          this.listLoading = false
      },
      getServiceName(sid) {
        let name = "系統"
        this.serviceList.forEach(item => {
          if(item.id == sid) {
            name= item.name
          }})
        return name
      },
      handleBatch() {
        
        this.dialogVisible = true
      },
      dialogDetailOnClose() {
        this.dialogVisible = false
      },
    }
  }
  </script>
  <style lang="scss" scoped>
  
  </style>
  
